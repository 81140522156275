<template>
  <div class="text-gray-700" data-testid="price" :class="[getFontSize, containerStyles]">
    <template v-if="!pricesData.specific_prices || parseInt(pricesData.specific_prices.price) === -1 || pricesData.price == pricesData.regular_price">
      <span class="font-bold" :class="regularPriceStyles">{{ pricesData.price }}</span>
    </template>
    <template v-else>
      <div class="flex gap-2 items-baseline">
        <div class="text-red-600 rounded bg-red-100 px-1 py-0 font-bold text-sm flex items-center">
          {{ $t('products.discount_label') }} 
          {{ Math.round(parseFloat(pricesData.specific_prices.reduction) * 100) + '%' }}
        </div>
        <div class="text-neutral-900 !font-bold" :class="[getFontSize, discoundStyles]">{{ pricesData.price }}</div>
      </div>
      <div class="text-xs mt-1">
        {{ $t('List price') }}: <span class="line-through">{{ pricesData.regular_price }}</span>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { SpecificPrices } from '@vue-storefront/prestashop-api';

interface PricesData {
  specific_prices: SpecificPrices;
  price: string;
  discount_percentage: string;
  regular_price: string;
}

const props = withDefaults(
  defineProps<{
    pricesData: PricesData;
    size?: string;
    containerStyles?: string;
    discoundStyles?: string;
    regularPriceStyles?: string;
  }>(),
  {
    size: 'md',
  },
);

const getFontSize = computed(() => {
  return props.size === 'lg' ? 'text-2xl' : props.size === 'md' ? 'text-xl' : 'text-lg';
});
</script>
